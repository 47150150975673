import {React, Component} from 'react';
import Blesk from './img/blesk';


class Playlist extends Component {

  render() {

    const songs = ["Iron man - Black Sabbath", "Seven nation army - The White Stripes", "Až - Katapult", "Enter sandman - Metallica", "For whom the bell tolls - Metallica", "Dávám ti jeden den - Kabát", "Smells like teen spirit - Nirvana", "American idiot - Green Day", "Víte jak to bolí - Kabát", "Breaking the law - Judas Priest", "Under the Graveyard - Ozzy Osbourne", "Pohoda - Kabát", "Behind Blue eyes - Limp Bizkit", "I love Rock'N Roll - Joan Jett", "Knocking on heavens door - GnR", "Highway to hell - AC/DC", "Hledá se děda - Morčata Na Útěku", "Známka punku - Visací Zámek", "We will rock you - Queen", "Síla starejch vín - Škwor", "Žízeň - Kabát", "Malá dáma - Kabát", "Jahody mražený - Jiří Schellinger", "Smoke on the water - Deep Purple", "Jasná zpráva - Olympic", "T.N.T. - AC/DC", "Falling Slowly - Glen Hansard", "Livin on a prayer - Bon Jovi", "It’s my life - Bon Jovi", "V pi... na lehátku - Horkýže Slýže", "Teenagers - My Chemical Romance", "Holiday - Green Day", "Vlastní tvorba - Collapse"]

    return (
        <div id='playlist' className='section-div'>
            <div className='container center mar-bot mar-top'>
                <h1>PLAYLIST</h1>
            </div>
            <div className='container center'>
                <p className='mobhide'>co od nás na koncertech uslyšíte</p>
            </div>

            <Blesk className='blesk b1 flip mobhide'/>

            <div id='songs' className='container center'>
                <div className='songlist'>
                    {songs.slice(0,11).map((str, index) => (
                        <p key={index}>{str}</p>
                    ))}
                </div>

                <div className='songlist'>
                    {songs.slice(11,22).map((str, index) => (
                        <p key={index}>{str}</p>
                    ))}
                </div>

                <div className='songlist'>
                    {songs.slice(22,34).map((str, index) => (
                        <p key={index}>{str}</p>
                    ))}  
                </div>
            </div>

            
            <Blesk className='blesk b2 mobhide'/>

        </div>
    );
  }
}


export default Playlist;