import {React, Component} from 'react';
import Blesk from './img/blesk';
import Tricko from './img/tricko.jpg'
import Cepice from './img/cepice 1.jpg'
import CepiceRed from './img/cervena_cepice.png'
import CepiceGrey from './img/seda_cepice.png'
import Taska from './img/taska_final.png'

class Obchod extends Component {

  render() {
    return (
        <div id='obchod' className='section-div'>
          <div className='container center mar-bot'><h1>OBCHOD</h1></div>
            
            <Blesk className='blesk b1 flip mobhide'/>

            <div className='container shop'>

              <div>
                <button onClick={() => alert('Prosím napište nám e-mail s velikostí a počtem kusů. Děkujeme.')} className='clickable shop-item'>
                <img src={Tricko} className='shop-pic'/>
                <p>tričko Collapse - černá 320,-Kč</p>
                </button>
              </div>
              <div>
                <button onClick={() => alert('Bohužel je toto zboží aktuálně vyprodáno. Zkuste to prosím požději, případně nám napiště email a my zboží naskladníme.')} className='clickable shop-item'>
                <img src={Cepice} className='shop-pic'/>
                <p>čepice Collapse - černá Vyprodáno!</p>
                </button>
              </div>
              <div>
                <button onClick={() => alert('Prosím napište nám e-mail s velikostí a počtem kusů. Děkujeme.')} className='clickable shop-item'>
                <img src={CepiceRed} className='shop-pic'/>
                <p>čepice Collapse - červená 200,-Kč</p>
                </button>
              </div>
              <div>
                <button onClick={() => alert('Prosím napište nám e-mail s velikostí a počtem kusů. Děkujeme.')} className='clickable shop-item'>
                <img src={CepiceGrey} className='shop-pic'/>
                <p>čepice Collapse - šedá 200,-Kč</p>
                </button>
              </div>
              <div>
                <button onClick={() => alert('Prosím napište nám e-mail s velikostí a počtem kusů. Děkujeme.')} className='clickable shop-item'>
                <img src={Taska} className='shop-pic'/>
                <p>taška Collapse 220,-Kč</p>
                </button>
              </div>

            </div>
            <Blesk className='blesk b2 mobhide'/>
            
        </div>
    );
  }
}


export default Obchod;