import React from "react";

function Facebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100mm"
      height="100mm"
      version="1.1"
      viewBox="39 143 110 100"
      className={props.className}
    >
      <g>
        <path
          fill="#fff"
          fillOpacity="1"
          strokeWidth="1.658"
          d="M42.432 243.699c-2.301-2.301-2.647-8.91-2.647-50.624 0-45.766.153-48.13 3.317-51.294 3.162-3.162 5.528-3.317 50.584-3.317 45.055 0 47.421.155 50.583 3.317 3.164 3.163 3.317 5.528 3.317 51.155 0 57.43 1.468 53.716-20.936 52.982l-14.721-.482v-43.12l6.05-.505c4.596-.384 6.283-1.38 7.025-4.147 1.545-5.76 1.403-5.91-6.027-6.448-6.896-.5-7.06-.65-7.578-6.986-.944-11.533.48-14 8.415-14.575 6.234-.451 7.1-1.015 7.551-4.917.432-3.733-.266-4.58-4.555-5.521-6.152-1.351-15.222 1.416-19.257 5.875-1.952 2.157-3.302 7.117-3.932 14.45l-.96 11.164-6.22.515c-5.627.465-6.218.969-6.218 5.29 0 4.32.591 4.824 6.219 5.29l6.22.514v43.12l-26.792.456c-22.729.386-27.193.053-29.438-2.192zm52.083-19.723v-17.314l-6.22-.515-6.219-.515V187.39l6.22-.515c6.056-.501 6.219-.687 6.219-7.102 0-8.015 3.246-16.126 8.235-20.577 2.982-2.661 6.063-3.317 15.57-3.317h11.852l.498 8.607.498 8.608-7.132.514c-6.937.5-7.146.684-7.646 6.733l-.515 6.22h6.636c3.65 0 6.947.56 7.328 1.243.38.685.237 4.976-.317 9.537-.994 8.177-1.095 8.3-7.228 8.807l-6.219.515V241.429l12.853-.484 12.854-.485v-96.192H45.59l-.442 46.415c-.243 25.529-.08 47.358.363 48.511.597 1.555 7.017 2.095 24.904 2.095h24.1z"
        ></path>
      </g>
    </svg>
  );
}

export default Facebook;