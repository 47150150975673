import {React, Component} from 'react';
import Telefon from './img/telefon';
import Email from './img/email';
import Messanger from './img/messanger'

class Kontakt extends Component {

  render() {
    return (
        <div id='kontakt' className='section-div'>
          <div className='container center mar-bot-all'><h1>KONTAKT</h1></div>
          <div className='social-div'>
            <Telefon className='social-img'/>
            <h2 className='social-text'>+420 733 735 117</h2>
          </div>
          <div className='social-div email-div'>
            <Email className='social-img'/>
            <h2 className='email-text'>kapela.collapse@gmail.com</h2>
          </div>
          <div className='social-div'>
            <Messanger className='social-img'/>
            <h2 className='social-text'>Kapela Collapse</h2>
          </div>
        </div>
    );
  }
}


export default Kontakt;