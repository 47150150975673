import {React, Component} from 'react';

import Icon from './img/collapse stranky logo.jsx'
import Navigator from './navigator.jsx';

class Homepage extends Component {

  render() {
    return (
        <div id='homepage'>
            <div className='container center'>
                <Icon/>
             </div>
             <div id='Navbar'>
              <Navigator classType={'menu_item'}/>
            </div>
            <div id='first_line'></div>
        </div>
    );
  }
}


export default Homepage;