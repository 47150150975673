import React from "react";

function Insta(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100mm"
      height="100mm"
      version="1.1"
      viewBox="47 140 110 107"
      className={props.className}
    >
      <g>
        <path
          fill="#fff"
          fillOpacity="1"
          strokeWidth="1.791"
          d="M60.604 245.84c-3.194-1.576-7.425-5.48-9.401-8.677-3.294-5.329-3.593-8.9-3.593-42.85 0-41.109.832-44.888 11.28-51.258 5.193-3.167 9.185-3.496 42.444-3.496 33.236 0 37.253.33 42.434 3.49 11.69 7.127 12.185 9.217 12.185 51.354 0 35.41-.248 38.334-3.639 42.876-7.786 10.429-12.59 11.51-50.952 11.465-28.352-.033-36.045-.581-40.758-2.905zm81.178-5.38c8.754-5.736 9.697-10.282 9.668-46.653-.028-36.285-.69-39.484-9.51-46.03-4.733-3.513-7.32-3.754-40.292-3.754-34.62 0-35.337.077-41.092 4.394-8.558 6.419-9.707 12.758-9.05 49.92l.581 32.823 6.69 6.126 6.688 6.126h35.906c30.337 0 36.604-.457 40.41-2.952zm-56.619-15.403c-28.388-14.342-23.718-57.054 7.113-65.051 22.253-5.772 44.874 10.936 44.874 33.144 0 27.06-27.804 44.123-51.987 31.907zm23.726-1.422c14.03-3.897 22.889-15.87 22.889-30.938 0-13.235-11.969-26.467-26.387-29.172-14.132-2.651-31.975 10.982-33.985 25.966-1.958 14.598 7.997 29.929 22.2 34.192 6.884 2.067 7.678 2.064 15.283-.048zm-17.92-8.796c-12.284-5.673-16.733-21.553-9.406-33.57 8.147-13.362 26.512-15.053 37.17-3.424 4.93 5.38 5.88 7.798 5.875 14.95-.016 19.603-16.1 30.143-33.639 22.044zm18.306-3.616c2.526-.96 6.244-4.386 8.261-7.611 11.425-18.267-12.763-37.8-28.548-23.055-15.415 14.4.358 38.243 20.287 30.666zm18.127-45.214c-3.752-5.356-3.509-9.625.794-13.927 7.291-7.291 17.908-2.377 17.908 8.289 0 6.87-2.889 9.619-10.106 9.619-3.972 0-6.69-1.259-8.596-3.98zm14.117-4.444c1.353-3.525-2.038-7.693-6.258-7.693-4.644 0-6.53 7.189-2.633 10.038 3.579 2.617 7.371 1.617 8.891-2.345z"
        ></path>
      </g>
    </svg>
  );
}

export default Insta;