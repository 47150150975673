import {React, Component} from 'react';


class Navigator extends Component {

    scroll = (selector) => (event) => {
        const anchor = document.querySelector(selector)
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    render() {
        return(
                <>
                    <button className={'clickable ' + this.props.classType} onClick={this.scroll('#aboutus')}>O NÁS</button>
                    <button className={'clickable ' + this.props.classType} onClick={this.scroll('#playlist')}>PLAYLIST</button>
                    <button className={'clickable ' + this.props.classType} onClick={this.scroll('#song')}>VLASTNÍ TVORBA</button>
                    <button className={'clickable ' + this.props.classType} onClick={this.scroll('#kalendar')}>KONCERTY</button>
                    <button className={'clickable ' + this.props.classType} onClick={this.scroll('#obchod')}>OBCHOD</button>
                    <button className={'clickable ' + this.props.classType} onClick={this.scroll('#galerie')}>GALERIE</button>
                    <button className={'clickable ' + this.props.classType} onClick={this.scroll('#socials')}>SOCIÁLNÍ SÍTĚ</button>
                    <button className={'clickable ' + this.props.classType} onClick={this.scroll('#kontakt')}>KONTAKT</button>
                </> 
            )
    }
}


export default Navigator;