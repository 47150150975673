import {React, Component} from 'react';
import songImg from './img/song.png';
import Blesk from './img/blesk'
import openInNewTab from './utility';


class Song extends Component {

  render() {
    return (
        <div id='song' className='section-div'>
          <div className='container center mar-bot'>
            <h1>VLASTNÍ TVORBA</h1>
          </div>
          <Blesk className='blesk mobhide b3'/>
          <Blesk className='blesk mobhide flip b4'/>
          <div className='container center'>
            <button className='shop-item clickable' onClick={() => openInNewTab('https://www.youtube.com/watch?v=2d35PxoDdGAp')}>
                <img id='song-logo' src={songImg} alt='song_logo'/>
              </button>
          </div>
            


            <Blesk className='blesk mobhide b3 flip'/>
            <Blesk className='blesk mobhide b4'/>

            <div id='dolni-block'>
              <div className='container center'><h3>PRVNÍ SKLADBA - HAPPY TO DIE</h3></div>
            </div>
        </div>
    );
  }
}


export default Song;