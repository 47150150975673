import {React, Component} from 'react';
import {Link} from 'react-router-dom';

import Blesk from './img/blesk';
import Insta from './img/instagram'
import Facebook from './img/facebook'
import Youtube from './img/youtube'
import openInNewTab from './utility';
import Spotify from './img/spotify.png'


class Socials extends Component {

  render() {
    return (
        <div id='socials' className='section-div'>
          <div className='container center mar-bot'><h1>SOCIÁLNÍ SÍTĚ</h1></div>
          <Blesk className='blesk b1 flip mobhide'/>
          <div className='social-div'>
            <Link href="#" onClick = {() => openInNewTab('https://www.instagram.com/this_is_collapse/')}>
              <Insta className='social-img'/>
              <h2 className='social-text'>@this_is_collapse</h2>
            </Link>
          </div>
          <div className='social-div'>
            <Link href="#" onClick = {() => openInNewTab('https://www.facebook.com/kapelacollapse')}>
              <Facebook className='social-img'/>
              <h2 className='social-text'>Kapela Collapse</h2>
            </Link>
          </div>
          <div className='social-div'>
            <Link href="#" onClick = {() => openInNewTab('https://www.youtube.com/@kapelacollapse6094')}>
              <Youtube className='social-img'/>
              <h2 className='social-text'>Kapela Collapse</h2>
            </Link>
          </div>
          <div className='social-div'>
            <Link href="#" onClick = {() => openInNewTab('https://open.spotify.com/artist/7t3dAwY093EK3U9m5ewHHM?si=NSYMxrFdT_i6EqgIC3MK9Q')}>
              <img src={Spotify} width={120} height={120} className='social-img'/>
              <h2 className='social-text'>Collapse</h2>
            </Link>
          </div>
          <Blesk className='blesk b2 mobhide'/>     
        </div>
    );
  }
}


export default Socials;