import {React, Component, Fragment} from 'react';
import Icon from './img/logo maly jen cerny';
import OutsideClickHandler from 'react-outside-click-handler';
import Navigator from './navigator';

class Corner extends Component {

    state = {
        renderPopup: false,
    }


    renderPopup = () => {
        return(
            <div id='popup-div' className={ (this.state.renderPopup) ? 'slide-in' : ''}>
                <Navigator classType={'popup_item'}/>
            </div> 
        )
    }

    render() {
        return (
            <Fragment>
                <div id='corner'>

                    <div id='cornerlogo'>
                        <button className='menu_item' onClick={this.scroll}>
                            <Icon id='cornerlogo'/>
                        </button>
                    </div>

                    <button onClick={() => this.setState({'renderPopup': !this.state.renderPopup})} className='menu_item'>
                        <div id='burger_corner' className='clickable'>
                            <div id='rectangle_one'></div>
                            <div id='rectangle_two'></div>
                            <div id='rectangle_three'></div>
                        </div>
                    </button>

                    <div id='cornerback2'></div>
                    <div id='cornerback'></div>

                </div>

                <OutsideClickHandler onOutsideClick={() => this.setState({'renderPopup': false})}>
                    {this.renderPopup()}
                </OutsideClickHandler>
            
            </Fragment>
        );
    }
}


export default Corner;