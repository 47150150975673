import React from "react";
import {Outlet} from "react-router-dom";
import {styles} from '../styles.css'

import Corner from "./corner";


const Layout = () => {
  return (
    <>
      <Corner/>
      <Outlet/>
    </>
  );
};

export default Layout;