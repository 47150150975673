import {React, Component} from 'react';


class Aboutus extends Component {

  render() {
    return (
        <div id='o-nas' className='section-div'>
            <div id='aboutus' className='container center'>
                <h1 id='h1-aboutus'>O NÁS</h1>
            </div>
            <p className='popis'>
                Jsme studentská kapela, která vznikla kolem roku 2019 v Červeném Kostelci. Původně jsme se scházeli ve třech jako čtrnáctiletí kluci, kteří si rádi zahráli pro vlastní ucho. Později jsme ale chtěli, aby naše kapela byla kompletní, takže to chtělo basáka a zpěváka. Přes inzerát jsme je našli a mohli jsme se začít posouvat dopředu. Naše první vystoupení se odehrálo v září roku 2020 pro zaměstnance jedné červenokostelecké firmy. 
            </p>
            <br/>
            <p className='popis'>
                Co se týče hudby, tak hrajeme převážně převzaté skladby rockového a metalového žánru. Později bychom ovšem chtěli postupně přejít na vlastní písně. 
            </p>
            <br/>
            <p className='popis'>
                Aktuální složení kapely:
            </p>
            <br/>
            <p className='popis'>
                Kristýna Dušková - zpěv
            </p>
            <p className='popis '>
                Matouš Kubín - sólová kytara
            </p>
            <p className='popis '>
                Dominik Marks - rytmická kytara
            </p>
            <p className='popis '>
                Petr Novák - baskytara
            </p>
            <p className='popis '>
                Ondra Duchatsch - bicí
            </p>
        </div>
    );
  }
}


export default Aboutus;