import {React, Component} from 'react';

import Homepage from './homepage';
import Playlist from './playlist';
import Song from './song';
import Kalendar from './kalendar';
import Obchod from './obchod';
import Galerie from './galerie';
import Socials from './socials';
import Kontakt from './kontakt';
import Aboutus from './aboutus';
import Cookies from './cookies';

class Index extends Component {

  render() {
    return (
      <div id='index'>
        <Homepage/>
        <Aboutus/>
        <Playlist/>
        <Song/>
        <Kalendar/>
        <Obchod/>
        <Galerie/>
        <Socials/>
        <Kontakt/>
        <Cookies/>
      </div>
    );
  }
}


export default Index;