import {React, Component} from 'react';
import cookiesImg from './img/cookies.png';
import ReactGA from 'react-ga';


class Cookies extends Component {
  state = {
    "cookies": false,
    "display":true
  }

  setCookies= () => {
    this.setState({"cookies": true, "display":false})
  }

  declineCookies = () => {
    this.setState({"display": false})
  }

  componentDidUpdate(){
    if(this.state.cookies){
      ReactGA.initialize('G-12EX438MGP');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    return (
      <>
      {this.state.display &&
        (<div id='cookie-holder'>
        <div id='cookies'>
          <img src={cookiesImg} height={120}/>
          <h3 id='cookies-heading'>Používáme cookies!</h3>
          <p id='cookies-pp'>Tato stránka používá soubory cookies, kliknutím 
na souhlasím souhlasíte s jejich podmínkami.</p>
          <a id='co-jsou-susenky' href='https://www.cookie-lista.cz/co-je-cookies.html'>Co jsou cookies?</a>
          <div id='cookie-button-holder'>
            <button onClick={this.setCookies} className='cookie-button'>souhlasím</button>
            <button onClick={this.declineCookies} className='cookie-button'>nesouhlasím</button>
          </div>
        </div>
      </div>)}
      </>
    );
  }
}


export default Cookies;