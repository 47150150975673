import React from "react";

function Blesk(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35mm"
      height="35mm"
      version="1.1"
      viewBox="60 0 100 200"
      className= {props.className}
    >
      <g>
        <path
          fill="#FFF"
          strokeWidth="0.265"
          d="M126.493 135.81c-40.054-27.155-72.786-49.411-72.737-49.46.048-.048 10.491.443 23.206 1.092 12.714.649 23.143 1.154 23.175 1.122.032-.032-18.514-17.142-41.214-38.022C11.114 6.565 14.398 9.609 15.455 10.239c1.477.882 154.099 107.333 154.01 107.421-.064.065-54.116-11.41-60.477-12.84-.943-.212-1.089-.197-.79.078.203.186 20.668 18.113 45.48 39.838 49.284 43.155 46.262 40.49 45.898 40.464-.142-.01-33.029-22.236-73.083-49.39z"
        ></path>
      </g>
    </svg>
  );
}

export default Blesk;
