import {React, Component} from 'react';
import Blesk from './img/blesk'

class Kalendar extends Component {

  render() {
    return (
        <div id='kalendar' className='section-div'>
          <div className='container center mar-bot'>
            <h1>KONCERTY</h1>
          </div>
          <Blesk className='blesk b3 mobhide'/>

          <div className='container center koncerty'>
            <iframe src="https://calendar.google.com/calendar/embed?height=400&wkst=2&bgcolor=%23616161&ctz=Europe%2FPrague&showTitle=0&showNav=1&showPrint=0&showCalendars=0&showTz=0&showTabs=0&showDate=1&src=ZjRlYmNiM2Q0ZTU0NWJhNTQ5MzM5YWY0MTBkN2Q0NDU5YjljNTEyMzJjMGRiNzk0NjM0NjA3ZGE1MDJiNzc4ZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23D50000" width="600" height="400"></iframe>
          </div>

          <Blesk className='blesk b4 mobhide'/>

        </div>
    );
  }
}


export default Kalendar;