import {React, Component} from 'react';
import {Link} from 'react-router-dom';

import Foto from './img/foto.png'
import Video from './img/video.png'
import Blesk from './img/blesk';
import openInNewTab from './utility'

class Galerie extends Component {

  render() {
    return (
        <div id='galerie' className='section-div'>
          
          <div className='container center mar-bot'><h1>GALERIE</h1></div>
          <Blesk className='blesk mobhide flip b4'/>

          <div className='container gallery'>

            <div>
              <button className='shop-item clickable' onClick={() => openInNewTab('https://drive.google.com/drive/folders/1t3bV_Vn1M8lpgSogVAnMDpOD5OAQykxp')}>
                <img src={Foto} className='gal-foto'/>
                <p>foto</p>
              </button>
            </div>

            <div>
              <button className='shop-item clickable'>
                <img src={Video} className='gal-video' onClick={() => openInNewTab('https://www.youtube.com/@kapelacollapse6094')}/>
                <p>video</p>
              </button>
            </div>

          </div>
          <Blesk className='blesk mobhide b3 flip'/>
        </div>
    );
  }
}


export default Galerie;